import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';

import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceTagsApiService } from '../api/device-tags-api.service';

import { tempAllTags } from './mappers/utils';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceTagApiService implements DeviceTagsApiService {
	public readonly allDeviceTags = signal<string[]>([]);
	private readonly logger: ILogger;
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		logger: ILogger,
		private readonly oktaService: OktaInterfaceService
	) {
		this.logger = logger.createScopedLogger('SysApiDeviceTagApiService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService,
			onLogOut: this.suspendService
		});
	}

	private initService = (): void => {
		this.destroy$ = new Subject();
		this.allDeviceTags.set(tempAllTags);
	};

	private suspendService = (): void => {
		this.destroy$.next();
		this.destroy$.complete();
		this.allDeviceTags.set([]);
	};
}
